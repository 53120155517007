import { createMuiTheme } from '@material-ui/core';

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 415,
      md: 769,
      lg: 1160,
    },
  },
  palette: {
    primary: {
      contrastText: '#fff',
      dark: '#00864',
      light: '#8a85ea',
      main: '#544fc3',
    },
    secondary: {
      contrastText: '#fff',
      dark: '#ae5b21',
      light: '#262626',
      main: '#e68943',
    },
    error: {
      main: '#d02e37',
    },
    warning: {
      main: '#ffd057',
    },
    info: {
      main: '#2757ff',
    },
    success: {
      main: '#18ab6e',
    },
    background: {
      black: '#000',
      default: '#ececec',
      surface: '#f4f4f4',
    },
  },
  typography: {
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '28px',
  },
  props: {
    MuiButton: {
      disableRipple: true,
      disableElevation: true,
      variant: 'contained',
    },
  },
});

theme.overrides.MuiAppBar = {
  root: {
    alignItems: "flex-start",
    boxShadow: 'none',
    margin: 'auto',
    maxWidth: '1160px',
    padding: '20px 24px 0',
  },
}

theme.overrides.MuiButton = {
  root: {
    fontSize: '14px',
    fontWeight: '700',
    lineHeight: '16px',
    padding: '10px 14px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText,
    '&:active': {
      backgroundColor: theme.palette.secondary.dark,
    },
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
    '&$disabled': {
      backgroundColor: theme.palette.background.surface,
    },
  },
}

theme.overrides.MuiInputLabel = {
  root: {
    lineHeight: '1',
    '&.Mui-focused': {
      color: theme.palette.secondary.main,
    },
  },
}

theme.overrides.MuiInputBase = {
  root: {
    color: '#0A0A0A',
    fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
    padding: '0',
    '& fieldset': {
      borderColor: '#0A0A0A',
    },
    '& input': {
      padding: '17px 14px',
    },
    '&.Mui-focused': {
      '& fieldset.MuiOutlinedInput-notchedOutline': {
        borderColor: theme.palette.secondary.main,
      },
    },
  },
}

theme.overrides.MuiInput = {
  underline: {
    '&.Mui-focused': {
      '&::after': {
        borderBottom: '2px solid ' + theme.palette.secondary.main,
      },
    },
  },
}

///////////////////////////////////////
// Need sizes and line heights for this
///////////////////////////////////////
theme.typography.h1 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '2.5rem',
  lineHeight: '3.25rem',
  fontWeight: '300',
  [theme.breakpoints.up('md')]: {
    fontSize: '3.25rem',
    lineHeight: '4.25rem',
  },
  [theme.breakpoints.up('lg')]: {
    fontSize: '5rem',
    lineHeight: '6rem',
  },
};

theme.typography.h2 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '32px',
  fontWeight: '500',
  lineHeight: '40px',
  [theme.breakpoints.up('md')]: {
    fontSize: '40px',
    lineHeight: '52px',
  },
};

theme.typography.h3 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '28px',
  fontWeight: '500',
  lineHeight: '36px',
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '44px',
  },
};

theme.typography.h4 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '24px',
  fontWeight: '500',
  lineHeight: '32px',
  [theme.breakpoints.up('md')]: {
    fontSize: '32px',
    lineHeight: '44px',
  },
};

theme.typography.h5 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
};

theme.typography.h6 = {
  fontFamily: ['Poppins', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    fontSize: '24px',
    lineHeight: '32px',
  },
};

theme.typography.subtitle1 = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '20px',
  fontWeight: '500',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    fontSize: '1.5rem',
    lineHeight: '2rem',
  },
};

theme.typography.subtitle2 = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '18px',
  fontWeight: '300',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    fontSize: '20px',
    lineHeight: '28px',
  },
};
  
theme.typography.body1 = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '16px',
  fontWeight: '400',
  lineHeight: '28px',
  [theme.breakpoints.up('md')]: {
    fontSize: '18px',
    lineHeight: '32px',
  },
};

///////////////////////////////////////
// Need sizes and line heights for this
///////////////////////////////////////
theme.typography.body2 = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '0.875rem',
  lineHeight: '1.25rem',
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
    lineHeight: '1.75rem',
  },
};

///////////////////////////////////////
// Need sizes and line heights for this
///////////////////////////////////////
theme.typography.button = {
  backgroundColor: theme.palette.secondary.main,
  color: theme.palette.secondary.contrastText,
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '14px',
  fontWeight: '500',
  letterSpacing: '1.25px',
  lineHeight: '16px',
  textTransform: 'uppercase',
};

///////////////////////////////////////
// Need sizes and line heights for this
///////////////////////////////////////
theme.typography.caption = {
  fontFamily: ['Roboto', 'Helvetica', 'Arial', 'sans-serif'].join(','),
  fontSize: '12px',
  lineHeight: '16px',
};

export default theme;
