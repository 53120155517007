// @flow

import * as React from "react";
import {
  AppBar,
  Link
} from "@material-ui/core";
import CMLogo from "./logo";
import { Link as RouterLink } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  className: string,
}

const CMHeader = ({ className }: Props): React.Node => {
  const useStyles = makeStyles((theme) => ({
    cmLogo: {
      marginLeft: '8.5%',
      marginTop: '20px',
      height: '50px',
    },
    root: {
      alignItems: "flex-start",
    },
  }));
  const {
    cmLogo,
    root,
  } = useStyles();
  
  className = className ?? root;

  return (
    <AppBar className={className} color="transparent" position="static">
      <Link component={RouterLink} to="/">
        <CMLogo className={cmLogo} />
      </Link>
    </AppBar>
  );
};

export default CMHeader;
