// @flow

import * as React from "react";
import joinClasses from "../utils/join-classes";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

type Props = {
  alt: string,
  className?: string,
  isLoading?: boolean,
  src: string,
};

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
  },
}));

const CMImage = (props: Props): React.Node => {
  const { isLoading = false, ...imgProps } = props;
  const classes = useStyles();
  return isLoading !== true ? (
    /* eslint-disable */
    // safely disable eslint rule because alt is required
    <img
      className={joinClasses({
        [classes.root]: true,
        [props?.className ?? ""]: props?.className != null,
      })}
      {...imgProps}
    />
  ) : (
    <CircularProgress />
  );
};

export default CMImage;
