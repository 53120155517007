// @flow

import * as React from "react";
import CMImage from "./image";
import logoSVG from "../assets/svg/predictively-logo.svg";

type Props = {
  alt?: string,
  className?: string,
  src?: string,
};

const CMLogo = ({
  alt = "Predictively",
  className,
  src = logoSVG,
}: Props): React.Node => <CMImage alt={alt} className={className} src={src} />;

export default CMLogo;
